













































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import TabMenuItemComponent from '@/components/TabMenuItemComponent.vue';
import TabMenuItemParams from '@/utils/types/TabMenuItemParams';
import CommunityUser from '@/models/graphql/CommunityUser';
import ExhibitorPortalUserDetailAboutComponent
  from '@/components/exhibitor-portal/ExhibitorPortalUserDetailAboutComponent.vue';
import { Portal } from 'portal-vue';
import ExhibitorTag from '@/models/graphql/ExhibitorTag';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';

@Component({
  components: {
    ButtonIconComponent,
    FontAwesomeComponent,
    ExhibitorPortalUserDetailAboutComponent,
    TabMenuItemComponent,
    Portal,
  },
})
export default class ExhibitorPortalUserDetailWidget extends mixins(
  VueRegisterStoreWidget,
  VueBaseWidget,
) {
  protected baseStoreName = 'ExhibitorPortalUserDetailWidgetStore';

  @Prop({
    required: false,
    default: () => [],
  })
  private data!: CommunityUser[];

  @Prop({
    required: false,
    default: () => [],
  })
  private exhibitorTags!: ExhibitorTag[];

  @Prop({
    required: false,
    default: () => [],
  })
  private tabConfigs!: TabMenuItemParams[];

  @Prop({
    required: false,
    default: null,
  })
  private mainTabConfig!: TabMenuItemParams | null;

  private oldBreakpoint = this.breakpoint.value;

  get fullPrefixedName(): string {
    return CommunityUser.hydrate(this.data[0]).getFullPrefixedName;
  }

  created(): void {
    this.setDataConfig();
  }

  updated(): void {
    this.setHeight();
  }

  @Watch('breakpoint.value')
  updatePages(): void {
    if (this.oldBreakpoint === 'xl' && (this.isTablet || this.isMobile)) {
      if (this.mainTabConfig?.route
          && this.tabConfigs.length > 0
          && this.tabConfigs[0].targetPage
          && this.$route.meta?.pageId) {
        this.$router.push({
          name: this.mainTabConfig?.route,
        });
      }
    }
    this.oldBreakpoint = this.breakpoint.value;
  }

  setHeight(): void {
    const footerEl = document.querySelector('#app > .footer');
    if (this.$el) {
      const footerHeight = footerEl ? footerEl.getBoundingClientRect().height + 10 : 0;
      this.$el.setAttribute('style', `height: calc(${document.body.clientHeight - footerHeight}px - var(--main-header-height));`);
    }
  }

  private goBackToDefaultPage(): void {
    const { params } = this.$route;
    this.$router.push({
      name: this.tabConfigs[0].route,
      params,
    });
  }
}
